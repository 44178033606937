<template>
  <div class="topNav">
    <img src="../../assets/images/home/Frame.svg" alt="">
    <div>
      <a-button type="primary" shape="round" @click="$router.push('/register')">Sign up</a-button>
    </div>
  </div>
  <div class="login-cont">
    <img src="../../assets/images/login/logo.svg" alt="" />
  </div>
  <div class="steps-result">
    <img src="../../assets/images/login/result-icon.svg" alt="">
    <div class="main-res">Reset password succeeded!</div>
    <div class="fu-res">
      You can log in with the new password!
    </div>
    <a-space>
      <a-button @click="$router.push('/')">Back to home</a-button>
      <a-button type="primary" @click="$router.push('/login')">Log in</a-button>
    </a-space>
  </div>
</template>

<script setup>

</script>

<style lang="less" scoped>
.topNav {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px 0 82px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.login-cont {
  text-align: center;
  margin-top: 80px;
}

.steps-result {
  text-align: center;
  margin-top: 108px;

  .main-res {
    color: var(--color-text-1);
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    margin-top: 20px;
    line-height: 22px;
  }

  .fu-res {
    color: var(--color-text-3);
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 16px;
  }
}
</style>
